import React, {Component} from "react"
import styles from "./Header.module.css"
// import {ReactComponent as LogoIcon} from "./parts/img/logo.svg"
import {ReactComponent as SignatureIcon} from "./parts/img/signature.svg"

interface Props {}

export class Header extends Component<Props, {}> {
    public render() {
        return (
            <header className={styles.root}>
                <div className={styles.wrapper}>
                    <h1 className={styles.h1}>
                        <img src="/finallogo.png" alt="fairfax-logo" className={styles.logo} />
                        {/* <LogoIcon className={styles.logo} />
                        <span className={styles.logoText}>UNIVERSITY OF FAIRFAX</span> */}
                    </h1>
                    <div className={styles.rightWrapper}>
                        <SignatureIcon className={styles.signatureIcon} />
                        <span className={styles.text}>APPLY TO UOF</span>
                    </div>
                </div>
            </header>
        )
    }
}
